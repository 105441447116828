import history from "@/history";
import utils from "./utils";

class aURL {
    private url: URL;

    constructor(path: string, noOrigin: boolean = false) {
        const base = !noOrigin ? window.location.origin : window.location.href;
        this.url = new URL(path, base);
    }

    // Expose the searchParams for easy access and manipulation
    get searchParams(): URLSearchParams {
        return this.url.searchParams;
    }

    // Method to append a search parameter and return this for chaining
    appendSearchParam(key: string, value: string): this {
        this.url.searchParams.append(key, value);
        return this;
    }

    // Method to open the URL, optionally in a new tab, and return this for chaining
    open(inNewTab: boolean = false): this {
        const target = inNewTab ? '_blank' : '_self';
        window.open(this.url.toString().replace(':uid', utils.uid), target);
        return this;
    }

    // Method to open the URL using history
    openWithHistory(): void {

        (history || utils.history).push(this.url.toString().replace(':uid', utils.uid).replace(location.origin, ''));
    }

    // Convert the URL object to a string
    toString(): string {
        return this.url.toString();
    }
}

export default aURL;
